<template>
  <!--
    TODO: this uses the HeadlessUI Menu directly
    this should be refactored to use a Dropdown that has a lot of this defaulted
  -->
  <h-menu as="div" class="relative">
    <h-menu-button class="border rounded border-brownish-gray-300 hover:bg-brownish-gray-100 w-5 h-5 flex flex-row items-center justify-center">
      <n-icon name="chevron-down" scale="1.2" class="text-brownish-gray-600"></n-icon>
    </h-menu-button>
    <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-out"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
    >
      <h-menu-items class="absolute z-30 mt-2 bg-white border rounded border-brownish-gray-300 focus:outline-none shadow-md">
        <div class="h-128 min-w-96 overflow-y-scroll">
          <template v-if="!isGlobal">
            <div class="px-2 py-1 bg-brownish-gray-100 text-brownish-gray-500 text-xs uppercase">applications</div>
            <template v-if="apps.length === 0">
              <div class="flex flex-row items-center gap-x-2 px-3 py-2 border-t border-brownish-gray-300">
                <n-icon name="rectangle-vertical-history" class="block text-brownish-gray-300"></n-icon>
                <h3 class="text-brownish-gray-300">No applications</h3>
              </div>
            </template>
            <div v-for="app in apps" :key="app.id" class="border-t border-brownish-gray-300 select-none">
              <h-menu-item class="focus:outline-none" v-slot="{ active }" :disabled="app.id === blockId">
                <button class="w-full px-3 py-2 flex flex-row items-center gap-x-2"
                        :class="[active || app.id === blockId ? 'bg-brownish-gray-100' : '', app.id === blockId ? 'cursor-default' : 'cursor-pointer']"
                        @click="goToBlock(app)">
                  <n-icon :name="getBlockIconName(app)" class="text-brownish-gray-400"></n-icon>
                  <span :class="app.id === blockId ? 'font-semibold' : ''">{{ app.name }}</span>
                </button>
              </h-menu-item>
            </div>
          </template>
          <template v-if="!isGlobal">
            <div class="border-t border-brownish-gray-300 px-2 py-1 bg-brownish-gray-100 text-brownish-gray-500 text-xs uppercase">datastores</div>
            <template v-if="datastores.length === 0">
              <div class="flex flex-row items-center gap-x-2 px-3 py-2 border-t border-brownish-gray-300">
                <n-icon name="rectangle-vertical-history" class="block text-brownish-gray-300"></n-icon>
                <h3 class="text-brownish-gray-300">No datastores</h3>
              </div>
            </template>
            <div v-for="ds in datastores" :key="ds.id" class="border-t border-brownish-gray-300 select-none">
              <h-menu-item class="focus:outline-none" v-slot="{ active }" :disabled="ds.id === blockId">
                <button class="w-full px-3 py-2 flex flex-row items-center gap-x-2"
                        :class="[active || ds.id === blockId ? 'bg-brownish-gray-100' : '', ds.id === blockId ? 'cursor-default' : 'cursor-pointer']"
                        @click="goToBlock(ds)">
                  <n-icon :name="getBlockIconName(ds)" class="text-brownish-gray-400"></n-icon>
                  <span :class="ds.id === blockId ? 'font-semibold' : ''">{{ ds.name }}</span>
                </button>
              </h-menu-item>
            </div>
          </template>
          <template v-if="!isGlobal">
            <div class="border-t border-brownish-gray-300 px-2 py-1 bg-brownish-gray-100 text-brownish-gray-500 text-xs uppercase">additional infrastructure</div>
            <template v-if="otherBlocks.length === 0">
              <div class="flex flex-row items-center gap-x-2 px-3 py-2 border-t border-brownish-gray-300">
                <n-icon name="rectangle-vertical-history" class="block text-brownish-gray-300"></n-icon>
                <h3 class="text-brownish-gray-300">No additional infrastructure</h3>
              </div>
            </template>
            <div v-for="block in otherBlocks" :key="block.id" class="border-t border-brownish-gray-300 select-none">
              <h-menu-item class="focus:outline-none" v-slot="{ active }" :disabled="block.id === blockId">
                <button class="w-full px-3 py-2 flex flex-row items-center gap-x-2"
                        :class="[active || block.id === blockId ? 'bg-brownish-gray-100' : '', block.id === blockId ? 'cursor-default' : 'cursor-pointer']"
                        @click="goToBlock(block)">
                  <n-icon :name="getBlockIconName(block)" class="text-brownish-gray-400"></n-icon>
                  <span :class="block.id === blockId ? 'font-semibold' : ''">{{ block.name }}</span>
                </button>
              </h-menu-item>
            </div>
          </template>
          <template v-if="isGlobal">
            <div class="px-2 py-1 bg-brownish-gray-100 text-brownish-gray-500 text-xs uppercase">domains</div>
            <template v-if="domains.length === 0">
              <div class="flex flex-row items-center gap-x-2 px-3 py-2 border-t border-brownish-gray-300">
                <n-icon name="rectangle-vertical-history" class="block text-brownish-gray-300"></n-icon>
                <h3 class="text-brownish-gray-300">No domains</h3>
              </div>
            </template>
            <div v-for="domain in domains" :key="domain.id" class="border-t border-brownish-gray-300 select-none">
              <h-menu-item class="focus:outline-none" v-slot="{ active }" :disabled="domain.id === blockId">
                <button class="w-full px-3 py-2 flex flex-row items-center gap-x-2"
                        :class="[active || domain.id === blockId ? 'bg-brownish-gray-100' : '', domain.id === blockId ? 'cursor-default' : 'cursor-pointer']"
                        @click="goToBlock(domain)">
                  <n-icon :name="getBlockIconName(domain)" class="text-brownish-gray-400"></n-icon>
                  <span :class="domain.id === blockId ? 'font-semibold' : ''">{{ domain.name }}</span>
                </button>
              </h-menu-item>
            </div>

          </template>
        </div>
      </h-menu-items>
    </transition>
  </h-menu>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Menu as HMenu, MenuButton as HMenuButton, MenuItem as HMenuItem, MenuItems as HMenuItems } from '@headlessui/vue'
import blockStore from '@/store/blocks'
import getBlockIconName from '@/lib/get_block_icon_name'
import useGlobalStack from '@/lib/use_global_stack'
import Block from '@/models/block'
import routeToBlock from '@/lib/route_to_block'

const props = defineProps<{
  orgName: string
  stackId: number
  envId: number
  blockId: number
}>()

const apps = computed(() => blockStore.query(props.orgName, props.stackId).filter(block => block.type === 'Application'))
const datastores = computed(() => blockStore.query(props.orgName, props.stackId).filter(block => block.type === 'Datastore'))
const otherBlocks = computed(() => blockStore.query(props.orgName, props.stackId).filter(block => block.type !== 'Application' && block.type !== 'Datastore'))

const { isGlobal } = useGlobalStack(props.orgName, props.stackId)
const domains = computed(() => blockStore.query(props.orgName, props.stackId).filter(block => block.type === 'Domain'))

const route = useRoute()
const router = useRouter()
const goToBlock = (block: Block) => {
  routeToBlock(route, router, block)
}
</script>
