<template>
  <div class="flex flex-row items-center gap-x-6">
    <div v-if="orgName && stack" class="flex flex-row items-center gap-x-2">
      <n-icon name="fas/layer-group" scale="1.5" class="text-brownish-gray-400"></n-icon>
      <div v-if="false" class="relative w-9 h-9 flex flex-row items-center justify-center">
        <n-icon name="fas/layer-group" scale="1.5" class="text-brownish-gray-400"></n-icon>
        <div v-if="false" class="absolute -bottom-0.5 -right-0.5">
          <div class="flex flex-row items-center justify-center bg-brownish-gray-200 rounded w-5 h-5">
            <n-icon name="aws" scale="1.2"></n-icon>
          </div>
        </div>
      </div>
      <div>
        <p v-if="false" class="text-xs text-brownish-gray-400">stack</p>
        <div class="flex flex-row items-center gap-x-2">
          <router-link :to="{ name: 'stack_overview', params: { orgName, stackId } }"
                       class="font-semibold hover:underline">{{ stack.name }}</router-link>
        </div>
      </div>
      <n-stack-menu :org-name="orgName" :stack-id="stackId"></n-stack-menu>
    </div>

    <template v-if="orgName && stack && env">
      <span class="text-brownish-gray-500">/</span>

      <div class="flex flex-row items-center gap-x-2">
        <n-icon :name="isPreview ? 'preview-env' : 'rectangle-vertical-history'" scale="1.5" class="text-brownish-gray-400"></n-icon>
        <div>
          <p v-if="false" class="text-xs text-brownish-gray-400">environment</p>
          <div class="flex flex-row items-center gap-x-2">
            <router-link v-if="orgName && stackId && envId" :to="{ name: 'env_overview', params: { orgName, stackId, envId } }"
                         class="font-semibold hover:underline">{{ env.name }}</router-link>
          </div>
        </div>
        <n-env-menu :org-name="orgName" :stack-id="stackId" :env-id="envId"></n-env-menu>
      </div>
    </template>

    <template v-if="orgName && stack && block">
      <span class="text-brownish-gray-500">/</span>

      <div class="flex flex-row items-center gap-x-2">
        <n-icon :name="blockIconName" scale="1.5" class="text-brownish-gray-400"></n-icon>
        <div>
          <p v-if="false" class="text-xs text-brownish-gray-500">static-site app</p>
          <div class="flex flex-row items-center gap-x-2">
            <span class="font-semibold">{{ block.name }}</span>
          </div>
        </div>
        <n-block-menu :org-name="orgName" :stack-id="stackId" :env-id="envId" :block-id="blockId"></n-block-menu>
      </div>
    </template>

    <template v-if="orgName && stackId && blockId && envId">
      <div class="flex flex-row items-center gap-x-2">
        <n-status-dot :app-status="appStatus"></n-status-dot>
        <n-icon v-if="workspaceStatus?.workingStatus" name="fas/cog" :spin="true" scale="0.8" class="text-blue-700"></n-icon>
      </div>
    </template>

  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import NStackMenu from '@/components/breadcrumb/StackMenu.vue'
import NEnvMenu from '@/components/breadcrumb/EnvMenu.vue'
import NBlockMenu from '@/components/breadcrumb/BlockMenu.vue'
import NStatusDot from '@/components/apps/StatusDot.vue'
import stackStore from '@/store/stacks'
import blockStore from '@/store/blocks'
import envStore from '@/store/envs'
import workspaceStatusStore from '@/store/workspace_statuses'
import getBlockIconName from '@/lib/get_block_icon_name'
import { EnvironmentType } from '@/models/environment'

const route = useRoute()
const orgName = computed(() => route.params.orgName as string)
const stackId = computed(() => {
  const stackId = route.params.stackId as string
  return stackId ? parseInt(stackId) : 0
})
const stack = computed(() => stackStore.find(orgName.value, stackId.value))
const blockId = computed(() => {
  const blockId = route.params.blockId as string
  return blockId ? parseInt(blockId) : 0
})
const block = computed(() => blockStore.find(orgName.value, stackId.value, blockId.value))
const blockIconName = computed(() => {
  if (!block.value) { return 'block' }
  return getBlockIconName(block.value)
})
const envId = computed(() => {
  let envId = route.params.envId as string
  if (!envId) { envId = route.params.envId as string }
  return envId ? parseInt(envId) : 0
})
const env = computed(() => envStore.find(orgName.value, stackId.value, envId.value))
const isPreview = computed(() => [EnvironmentType.Preview.toString(), EnvironmentType.PreviewsShared.toString()].includes(env.value?.type as string))

const workspaceStatus = computed(() => workspaceStatusStore.find(blockId.value, envId.value))
const appStatus = computed(() => workspaceStatus.value?.appStatus || 'not-launched')
</script>
