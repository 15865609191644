<template>
  <div id="app">
    <component :is="layout()">
      <router-view/>
    </component>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import Default from '@/layouts/Default.vue'
import Empty from '@/layouts/Empty.vue'
import Org from '@/layouts/Org.vue'
import Registry from '@/layouts/Registry.vue'
import RegistryWhite from '@/layouts/RegistryWhite.vue'
import useLiveStackWorkspaces from '@/lib/use_live_stack_workspaces'
import useLiveStackWorkspaceStatuses from '@/lib/use_live_stack_workspace_statuses'

const route = useRoute()

const layout = () => {
  switch (route.meta.layout) {
  case 'empty':
    return Empty
  case 'org':
    return Org
  case 'registry':
    return Registry
  case 'registry_white':
    return RegistryWhite
  default:
    return Default
  }
}

const orgName = computed(() => {
  return route.params.orgName as string || route.query.orgName as string
})
const stackId = computed(() => {
  return parseInt(route.params.stackId as string || route.query.stackId as string)
})

useLiveStackWorkspaces(orgName, stackId)
useLiveStackWorkspaceStatuses(orgName, stackId)
</script>
