import { getAppConfig } from '@/models/app_config'

export interface WebsocketMessage {
  source:string
  context: string
  content: string
  details?: Record<string, any> // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const getAppLogsUrl = async(orgName: string, stackId: number, appId: number, envId: number, start: Date): Promise<string> => {
  const appConfig = await getAppConfig()
  const url = new URL(appConfig.apiHost)
  const protocol = appConfig.nullstoneEnv === 'localhost' ? 'ws' : 'wss'
  let query = ''
  if (start) {
    query = `start=${start.toISOString()}`
  }
  if (query) { query = `?${query}` }
  return `${protocol}://${url.host}/orgs/${orgName}/stacks/${stackId}/apps/${appId}/envs/${envId}/app_logs${query}`
}

export const getRunLogsUrl = async (orgName: string, stackId: number, runUid: string): Promise<string> => {
  const appConfig = await getAppConfig()
  const url = new URL(appConfig.apiHost)
  const protocol = appConfig.nullstoneEnv === 'localhost' ? 'ws' : 'wss'
  return `${protocol}://${url.host}/orgs/${orgName}/stacks/${stackId}/runs/${runUid}/logs`
}

export const getBuildLogsUrl = async (orgName: string, stackId: number, buildId: number): Promise<string> => {
  const appConfig = await getAppConfig()
  const url = new URL(appConfig.apiHost)
  const protocol = appConfig.nullstoneEnv === 'localhost' ? 'ws' : 'wss'
  return `${protocol}://${url.host}/orgs/${orgName}/stacks/${stackId}/builds/${buildId}/logs`
}

export const getDeployLogsUrl = async (orgName: string, stackId: number, deployId: number): Promise<string> => {
  const appConfig = await getAppConfig()
  const url = new URL(appConfig.apiHost)
  const protocol = appConfig.nullstoneEnv === 'localhost' ? 'ws' : 'wss'
  return `${protocol}://${url.host}/orgs/${orgName}/stacks/${stackId}/deploys/${deployId}/logs`
}

export const getStackWorkspacesUrl = async (orgName: string, stackId: number): Promise<string> => {
  const appConfig = await getAppConfig()
  const url = new URL(appConfig.apiHost)
  const protocol = appConfig.nullstoneEnv === 'localhost' ? 'ws' : 'wss'
  return `${protocol}://${url.host}/orgs/${orgName}/stacks/${stackId}/workspaces`
}

export const getWorkspaceRunsUrl = async (orgName: string, stackId: number, workspaceUid: string): Promise<string> => {
  const appConfig = await getAppConfig()
  const url = new URL(appConfig.apiHost)
  const protocol = appConfig.nullstoneEnv === 'localhost' ? 'ws' : 'wss'
  return `${protocol}://${url.host}/orgs/${orgName}/stacks/${stackId}/workspaces/${workspaceUid}/runs`
}

export const getWorkspaceChangesUrl = async (orgName: string, stackId: number, blockId: number, envId: number): Promise<string> => {
  const appConfig = await getAppConfig()
  const url = new URL(appConfig.apiHost)
  const protocol = appConfig.nullstoneEnv === 'localhost' ? 'ws' : 'wss'
  return `${protocol}://${url.host}/orgs/${orgName}/stacks/${stackId}/blocks/${blockId}/envs/${envId}/changes`
}

export const getWorkspaceWorkflowUrl = async (orgName: string, stackId: number, blockId: number, envId: number, workflowId: number): Promise<string> => {
  const appConfig = await getAppConfig()
  const url = new URL(appConfig.apiHost)
  const protocol = appConfig.nullstoneEnv === 'localhost' ? 'ws' : 'wss'
  return `${protocol}://${url.host}/orgs/${orgName}/stacks/${stackId}/blocks/${blockId}/envs/${envId}/workspace_workflows/${workflowId}`
}

export const getIntentWorkflowUrl = async (orgName: string, stackId: number, workflowId: number): Promise<string> => {
  const appConfig = await getAppConfig()
  const url = new URL(appConfig.apiHost)
  const protocol = appConfig.nullstoneEnv === 'localhost' ? 'ws' : 'wss'
  return `${protocol}://${url.host}/orgs/${orgName}/stacks/${stackId}/intent_workflows/${workflowId}`
}

export const getActiveIntentWorkflowsUrl = async (orgName: string, stackId: number): Promise<string> => {
  const appConfig = await getAppConfig()
  const url = new URL(appConfig.apiHost)
  const protocol = appConfig.nullstoneEnv === 'localhost' ? 'ws' : 'wss'
  return `${protocol}://${url.host}/orgs/${orgName}/stacks/${stackId}/active_intent_workflows`
}

export const getStackWorkspaceStatusesUrl = async (orgName: string, stackId: number): Promise<string> => {
  const appConfig = await getAppConfig()
  const url = new URL(appConfig.apiHost)
  const protocol = appConfig.nullstoneEnv === 'localhost' ? 'ws' : 'wss'
  return `${protocol}://${url.host}/orgs/${orgName}/stacks/${stackId}/workspace_statuses`
}

export const getStackActiveWorkflowsUrl = async(orgName: string, stackId: number): Promise<string> => {
  const appConfig = await getAppConfig()
  const url = new URL(appConfig.apiHost)
  const protocol = appConfig.nullstoneEnv === 'localhost' ? 'ws' : 'wss'
  return `${protocol}://${url.host}/orgs/${orgName}/stacks/${stackId}/active_workspace_workflows`
}

export const getWorkspaceWorkflowsUrl = async(orgName: string, stackId: number, blockId: number, envId: number, pageSize = 10): Promise<string> => {
  const appConfig = await getAppConfig()
  const url = new URL(appConfig.apiHost)
  const protocol = appConfig.nullstoneEnv === 'localhost' ? 'ws' : 'wss'
  return `${protocol}://${url.host}/orgs/${orgName}/stacks/${stackId}/blocks/${blockId}/envs/${envId}/workspace_workflows?page=1&perPage=${pageSize}`
}

export const getStableWorkspaceConfigsUrl = async(orgName: string, stackId: number, blockId: number, envId: number): Promise<string> => {
  const appConfig = await getAppConfig()
  const url = new URL(appConfig.apiHost)
  return `${url.origin}/orgs/${orgName}/stacks/${stackId}/blocks/${blockId}/envs/${envId}/configs/stable`
}
